import {FC, ReactElement, useEffect} from "react";
import {useCreatedOrders, useOrdersRequest} from "@kashalot-web/react/dist";
import {GET_ORDER_WITH_FILTERS_ID, GET_REPORT_ID} from "../CompanyRequestIDs";
import OrdersTable from "../../../LocalUICore/OrdersTable";
import {useSnackbar} from "notistack"


const FinancesSection: FC = (): ReactElement => {
    const { enqueueSnackbar } = useSnackbar()
    const {getOrdersByFilters, ordersWithFilters, getReport, filters, applyFilters} = useCreatedOrders()
    const [status] = useOrdersRequest(GET_ORDER_WITH_FILTERS_ID)
    const [gettingReportStatus, gettingReportRemove] = useOrdersRequest(GET_REPORT_ID)

    useEffect(() => {
        getOrdersByFilters(GET_ORDER_WITH_FILTERS_ID)
    }, [])

    useEffect(() => {
        if (gettingReportStatus === "error") {
            enqueueSnackbar('Ошибка при формировании отчета', { variant: 'error' })
            gettingReportRemove()
        }
    }, [gettingReportStatus])

    return (
        <OrdersTable
            loading={status === "pending"}
            orders={ordersWithFilters}
            filters={filters}
            applyFilters={newFilters => applyFilters(newFilters, GET_ORDER_WITH_FILTERS_ID)}
            getReport={() => getReport(GET_REPORT_ID)}
        />
    )
}

export default FinancesSection
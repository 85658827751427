import {FC, ReactElement, useEffect} from "react";
import {useAdmin, useAdminRequest} from "@kashalot-web/react/dist";
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import {Button, Grid, Paper, Typography} from "@mui/material";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import {useNavigate} from "react-router-dom";

const mapClientsForTable = (clientsManager: IClientsManager | null) => {
    if (!clientsManager) {
        return []
    }
    type ResultType = {
        id: string,
        type: "company" | "branch",
        name: string,
        phone: string,
        mainCompany: string
    }
    const result: ResultType[] = []
    clientsManager.getCompanies().forEach(company => result.push({
        id: company.id,
        type: "company",
        name: company.name,
        phone: company.phoneNumber,
        mainCompany: ""
    }))
    clientsManager.getBranches().forEach(branch => result.push({
        id: branch.id,
        type: "branch",
        name: branch.name,
        phone: branch.phoneNumber,
        mainCompany: branch.companyName
    }))
    return result
}

const GET_CLIENTS_REQ_ID = "get-clients-" + makeID(8)

const ClientsSection: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {getClients, clientsManager} = useAdmin()
    const [getClientsStatus] = useAdminRequest(GET_CLIENTS_REQ_ID)

    useEffect(() => {
        getClients(GET_CLIENTS_REQ_ID)
    }, [])

    return (
        <MaterialTable
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Клиенты</Typography>}
            data={mapClientsForTable(clientsManager)}
            columns={[
                {
                    title: "Тип",
                    field: "type",
                    lookup: {
                        company: "Компания",
                        branch: "Филиал"
                    }
                },
                {title: "Название", field: "name"},
                {title: "Номер телефона", field: "phone"},
                {title: "Главная компания", field: "mainCompany"},
            ]}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true
            }}
            onRowClick={(event, data) => {
                if (data && data.id) {
                    navigate(`/client/${data.id}`)
                }
            }}
        />
    )
}

export default ClientsSection
import {FC, ReactElement, useEffect, useState} from "react";
import {Autocomplete, Button, CircularProgress, Grid, Paper, TextField, Typography} from "@mui/material";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist";

const GET_CLIENTS_ID = "get-clients-asdjfn2;lnkr"
const UPDATE_GROUP_DETAILS = "update-group-details-asdmlfkn3kljn"

const mapOrganizationsGorTable = (organizations: Array<ShortCompany | ShortBranch>) => {
    return organizations.map(org => {
        return {
            id: org.id,
            name: org.name,
            type: org._type
        }
    })
}

interface IOrganizationsGroup {
    group: DispatcherGroup
    allGroups: Array<DispatcherGroup>
    reset: () => void
}

const OrganizationsGroup: FC<IOrganizationsGroup> = ({group, allGroups, reset}): ReactElement => {
    const {
        getClients, clientsManager,
        addCompanyToGroup, deleteCompanyFromGroup, addBranchToGroup, deleteBranchFromGroup
    } = useDispatcher()
    const [getClientsStatus] = useDispatcherRequest(GET_CLIENTS_ID)
    const [addingOrganizationStatus] = useDispatcherRequest(UPDATE_GROUP_DETAILS)
    const [organizations, setOrganizations] = useState<Array<ShortCompany | ShortBranch>>([])
    const [freeOrganizations, setFreeOrganizations] = useState<Array<ShortCompany | ShortBranch>>([])
    const [selectedFreeOrganization, setSelectedFreeOrganization] = useState<ShortCompany | ShortBranch | null>(null)

    useEffect(() => {
        getClients(GET_CLIENTS_ID)
    }, [])

    useEffect(() => {
        if (clientsManager) {
            const companies: Array<ShortCompany> = []
            group.companies.forEach(companyID => {
                const company: ShortCompany | null = clientsManager.getCompanyByID(companyID)
                if (company) companies.push(company)
            })
            const branches: Array<ShortBranch> = []
            group.branches.forEach(branchID => {
                const branch: ShortBranch | null = clientsManager.getBranchByID(branchID)
                if (branch) branches.push(branch)
            })
            setOrganizations([...companies, ...branches])
        }
    }, [clientsManager, group, allGroups])

    useEffect(() => {
        if (clientsManager) {
            const allOrganizations = clientsManager.getOrganizations()
            setFreeOrganizations([...allOrganizations.filter(organization => {
                let flag: boolean = true
                allGroups.forEach(group => {
                    let candidateID: string | null = null
                    if (organization._type === "short-company") {
                        candidateID = group.companies.find(companyID => companyID === organization.id) || null
                    } else {
                        candidateID = group.branches.find(branchID => branchID === organization.id) || null
                    }
                    flag = !candidateID
                })
                return flag
            })])
        }
    }, [group, allGroups, clientsManager])


    const validateAddOrganization = () => {
        return addingOrganizationStatus !== "pending" && !!selectedFreeOrganization
    }
    const addOrganization = () => {
        if (selectedFreeOrganization?._type === "short-company") {
            // updateDispatcherGroupDetails({...group, companies: [...group.companies, selectedFreeOrganization.id]}, UPDATE_GROUP_DETAILS)
            addCompanyToGroup(group.id, selectedFreeOrganization.id, UPDATE_GROUP_DETAILS)
            setSelectedFreeOrganization(null)
            reset()
        } else if (selectedFreeOrganization?._type === "short-branch") {
            // updateDispatcherGroupDetails({...group, branches: [...group.branches, selectedFreeOrganization.id]}, UPDATE_GROUP_DETAILS)
            addBranchToGroup(group.id, selectedFreeOrganization.id, UPDATE_GROUP_DETAILS)
            setSelectedFreeOrganization(null)
            reset()
        }
    }
    const removeOrganization = (type: ShortCompany["_type"] | ShortBranch["_type"], id: string) => {
        // updateDispatcherGroupDetails({
        //     ...group,
        //     companies: group.companies.filter(companyID => companyID !== id),
        //     branches: group.branches.filter(branchID => branchID !== id)
        // }, UPDATE_GROUP_DETAILS)
        if (type === "short-company") {
            deleteCompanyFromGroup(group.id, id, UPDATE_GROUP_DETAILS)
        } else {
            deleteBranchFromGroup(group.id, id, UPDATE_GROUP_DETAILS)
        }
        setSelectedFreeOrganization(null)
        reset()
    }


    return (
        <MaterialTable
            // style={{width: "100%"}}
            title={<Typography variant={"h4"}>Компании</Typography>}
            columns={[
                {title: "Название", field: "name"}
            ]}
            data={mapOrganizationsGorTable(organizations)}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true
            }}
            editable={{
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        removeOrganization(oldData.type, oldData.id)
                        resolve(true)
                    })
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    // direction="column"
                                    alignItems={"center"}
                                    sx={{p: '18px 14px', boxSizing: "border-box"}}
                                >
                                    <Grid item sx={{mr: '9px'}}>
                                        <Autocomplete
                                            sx={{width: 240}}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={(option) => option.name}
                                            options={freeOrganizations}
                                            value={selectedFreeOrganization}
                                            onChange={(_, value) => setSelectedFreeOrganization(value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Компания"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item sx={{mr: '9px'}}>
                                        <Button
                                            variant={"contained"} disabled={!validateAddOrganization()}
                                            onClick={addOrganization}
                                        >
                                            Добавить
                                        </Button>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
        />
    )
}

export default OrganizationsGroup
import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {
    useDispatcher,
    useMap,
    useOrganization,
    useOrganizationRequest
} from "@kashalot-web/react/dist";
import PaperContainer from "../../../lab/Components/PaperContainer";
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Autocomplete,
    Button,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@mui/material";
import InputMask from "react-input-mask";
import AddressInput from "../../../lab/Input/AddressInputLab";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import RequisitesForm from "../../../LocalUICore/RequisitesForm";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BlankRequisites from "../../../LocalUICore/BlankRequisites";
import TransactionsTable from "../../../LocalUICore/TransactionsTable";

const UPDATE_ORGANIZATION_DETAILS_REQ_ID = "UPDATE_ORGANIZATION_DETAILS_REQ_ID_" + makeID(8)
const UPDATE_LEGAL_REQ_ID = "update-legal-entity-sadmlkfmasdlfm"
const FILL_BALANCE_REQ_ID = "fill-balance-asdfasdfasdf"

interface ICompanyQuestionnaire {
    company: Company | Branch
    transactions: Array<Transaction>
    reset: () => void
}

const CompanyQuestionnaire: FC<ICompanyQuestionnaire> = ({company, reset, transactions}): ReactElement => {
    const {updateOrganizationDetails, fillBalance, createLegal, updateLegal, deleteLegal} = useOrganization()
    const {suggestionsRequests, pushSuggestionRequest} = useMap()
    const {dispatcherOffice} = useDispatcher()


    const [profileCompany, setProfileCompany] = useState<Company | Branch>(company)
    const [newDay, setNewDay] = useState<number>(1)
    const handleNewDay = (newDayString: string) => {
        try {
            const newDayNumb = Number.parseInt(newDayString)
                setNewDay(newDayNumb)
        } catch (e) {
        }
    }
    const pushNewDay = () => {
        const newBillingDay = profileCompany.billingDays.slice()
        newBillingDay.push(newDay)
        newBillingDay.sort((a, b) => a > b ? 1 : -1)
        setProfileCompany({...profileCompany, billingDays: newBillingDay})
        setNewDay(1)
    }
    const validateNewDay = () => !profileCompany.billingDays.includes(newDay) && newDay >= 1 && newDay <= 31
    const deleteDay = (index: number) => {
        setProfileCompany({...profileCompany, billingDays: profileCompany.billingDays.filter((_, i) => i !== index)})
    }
    const handleProfileCompany = (param: "name" | "address" | "phoneNumber" | "limit" | "deferment", newValue: string) =>
        setProfileCompany({...profileCompany, [param]: newValue})
    const validateProfileCompany = () => {
        const {name, address, phoneNumber, limit, deferment, billingDays} = profileCompany
        let validDays = true
        if (billingDays.length !== company.billingDays.length) {
            validDays = false
        } else {
            validDays = billingDays.reduce((acc, day, ind) => day === company.billingDays[ind] && acc, true)
        }
        return name && address && PhoneNumber.validateWithMask(phoneNumber) &&
            (name !== company.name
                || address !== company.address
                || phoneNumber !== company.phoneNumber
                || limit !== company.limit
                || deferment !== company.deferment || !validDays)
    }
    const [statusSaveProfile, removeRequest] = useOrganizationRequest(UPDATE_ORGANIZATION_DETAILS_REQ_ID)
    const onSaveProfileCompany = () => {
        updateOrganizationDetails(profileCompany, UPDATE_ORGANIZATION_DETAILS_REQ_ID)
    }
    useEffect(() => {
        if (statusSaveProfile === "success" || statusSaveProfile === "error") {
            removeRequest()
            reset()
        }
    }, [statusSaveProfile])

    const [statusLegal, removeLegalRequest] = useOrganizationRequest(UPDATE_LEGAL_REQ_ID)
    useEffect(() => {
        if (statusLegal === "success" || statusLegal === "error") {
            reset()
            removeLegalRequest()
        }
    }, [statusLegal])



    const [statusFillBalance, removeFillBalance] = useOrganizationRequest(FILL_BALANCE_REQ_ID)
    const [additionBalance, setAdditionBalance] = useState<number>(0)
    const [comment, setComment] = useState<string>("")
    const [selectedCompanyLegal, setSelectedCompanyLegal] = useState<LegalEntity | undefined>()
    const [selectedDispatcherLegal, setSelectedDispatcherLegal] = useState<LegalEntity | undefined>()
    const validateAddition = () => additionBalance > 0 && !!selectedCompanyLegal && !!selectedDispatcherLegal && !!comment
    const onFillBalance = () => {
        if (additionBalance > 0 && !!selectedCompanyLegal && !!selectedDispatcherLegal) {
            if (company._type === "company") {
                fillBalance(selectedCompanyLegal.id, selectedDispatcherLegal.id, additionBalance, comment, "company", FILL_BALANCE_REQ_ID)
            } else if (company._type === "branch") {
                fillBalance(selectedCompanyLegal.id, selectedDispatcherLegal.id, additionBalance, comment, "branch", FILL_BALANCE_REQ_ID)
            }
        }
    }
    useEffect(() => {
        if (statusFillBalance === "success" || statusFillBalance === "error") {
            reset()
            removeFillBalance()
            setAdditionBalance(0)
            setComment("")
            setSelectedCompanyLegal(undefined)
            setSelectedDispatcherLegal(undefined)
        }
    }, [statusFillBalance])


    return (
        <Grid container wrap={"nowrap"} direction={"column"}>
            <PaperContainer
                title={"Профиль"}
                Icon={PersonIcon}
                item
                container
                sx={{p: '16px 0'}}
            >
                <Grid item>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid item>
                            <TextField
                                label={"Название"}
                                value={profileCompany.name}
                                onChange={event => handleProfileCompany("name", event.target.value)}
                            />
                        </Grid>
                        <Grid item><InputMask
                            mask="+7 (999) 999-9999"
                            value={profileCompany.phoneNumber ? profileCompany.phoneNumber : "+"}
                            onChange={(event) => handleProfileCompany("phoneNumber", event.target.value)}
                            children={((inputProps: any) =>
                                <TextField
                                    {...inputProps}
                                    label={"Номер телефона"}
                                    type="tel"
                                />) as unknown as ReactNode
                            }
                        /></Grid>
                        <Grid item><AddressInput
                            id={`company-edit-address-13kmf;;m2`}
                            label={"Адрес"}
                            defaultValue={profileCompany.address}
                            onChange={(newAddress) => handleProfileCompany("address", newAddress)}
                            suggestionsRequests={suggestionsRequests}
                            pushSuggestionRequest={pushSuggestionRequest}
                        /></Grid>
                        {company.balance !== undefined && (
                            <>
                                <Grid item>
                                    <TextField
                                        label={"Лимит"}
                                        value={profileCompany.limit}
                                        type={"number"}
                                        onChange={event => handleProfileCompany("limit", event.target.value)}
                                    />
                                </Grid>
                                <Grid item container spacing={2}>
                                    {profileCompany.billingDays.map((day, index) => {
                                        return (
                                            <Grid item key={`day-index-${index}`}>
                                                <Grid container alignItems={"center"} spacing={1}>
                                                    <Grid item>
                                                        <TextField
                                                            label={"День оплаты"}
                                                            sx={{width: 120}}
                                                            value={day}
                                                            type={"number"}
                                                            inputProps={{readOnly: true}}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Fab
                                                            size={"medium"}
                                                            color={"primary"}
                                                            onClick={() => deleteDay(index)}
                                                        >
                                                            <ClearIcon />
                                                        </Fab>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item>
                                        <Grid container spacing={1} alignItems={"center"}>
                                            <Grid item>
                                                <TextField
                                                    sx={{width: 120}}
                                                    value={newDay}
                                                    type={"number"}
                                                    onChange={event => handleNewDay(event.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size={"medium"}
                                                    onClick={pushNewDay}
                                                    color={"primary"}
                                                    disabled={!validateNewDay()}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={"Отсрочка"}
                                        value={profileCompany.deferment}
                                        type={"number"}
                                        onChange={event => handleProfileCompany("deferment", event.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={"Баланс"}
                                        value={company.balance}
                                        inputProps={{readOnly: true}}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item><Button
                            variant={"contained"}
                            disabled={!validateProfileCompany()}
                            onClick={onSaveProfileCompany}
                        >Сохранить</Button></Grid>
                    </Grid>
                </Grid>
            </PaperContainer>

            <PaperContainer
                title={"Пополнить баланс"}
                Icon={PersonIcon}
                item
                container
                sx={{p: '16px 0'}}
            >
                <Grid item>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid item><Autocomplete
                            sx={{width: 400}}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.legalName}
                            options={dispatcherOffice?.legalEntities || []}
                            value={selectedDispatcherLegal}
                            onChange={(_, value) => value && setSelectedDispatcherLegal(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Юр лицо диспетчерской (получатель)"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        /></Grid>

                        <Grid item><Autocomplete
                            sx={{width: 400}}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option.legalName}
                            options={company.legalEntities}
                            value={selectedCompanyLegal}
                            onChange={(_, value) => value && setSelectedCompanyLegal(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Юр лицо компании (отправитель)"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                        /></Grid>

                        <Grid item>
                            <TextField
                                label={"Сумма"}
                                value={additionBalance}
                                onChange={event => {
                                    const value = event.target.value
                                    try {
                                        const sum = Number.parseFloat(value)
                                        setAdditionBalance(sum)
                                    } catch (e) {
                                    }
                                }}
                                type={"number"}
                                autoComplete={'off'}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                sx={{width: 400}}
                                label={"Комментарий"}
                                value={comment}
                                onChange={event => setComment(event.target.value)}
                                autoComplete={'off'}
                            />
                        </Grid>

                        <Grid item><Button
                            variant={"contained"}
                            disabled={!validateAddition()}
                            onClick={onFillBalance}
                        >Пополнить</Button></Grid>
                    </Grid>
                </Grid>
            </PaperContainer>

            {company.legalEntities.map((legalEntity, index) => {
                return (
                    <Grid item key={legalEntity.id} sx={{mb: '18px'}}>
                        <Grid container>
                            <Paper component={Grid} item sx={{p: '16px'}}>
                                <RequisitesForm
                                    key={`requisites-${index}-${legalEntity.id}`}
                                    defaultRequisites={legalEntity}
                                    onSaveRequisites={newRequisites => updateLegal({...legalEntity, ...newRequisites}, UPDATE_LEGAL_REQ_ID)}
                                    onDelete={() => deleteLegal(legalEntity.id, UPDATE_LEGAL_REQ_ID)}
                                    actionType={"save"}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )
            })}

            <Grid item>
                <BlankRequisites
                    onCreate={newRequisites => createLegal(
                        {...newRequisites, id: ''},
                        (company._type === "company" ? company.id : ""),
                        (company._type === "branch" ? company.id : ""),
                        UPDATE_LEGAL_REQ_ID
                    )}
                />
            </Grid>
        </Grid>
    )
}

export default CompanyQuestionnaire
const emptyRequisites: Requisites = {
    legalName: "",
    legalAddress: "",
    bankBic: "",
    bankName: "",
    bankAccountNumber: "",
    correspondentBankAccountNumber: "",
    inn: "",
    kpp: "",
    directorName: "",
    accountantName: "",
    zipCode: "",
    taxType: "general"
}

export default emptyRequisites
import {FC, ReactElement, useEffect, useState} from "react"
import {Autocomplete, Box, Button, CircularProgress, Grid, Paper, TextField, Typography} from "@mui/material"
import useDispatcher from "@kashalot-web/react/dist/useDispatcher"
import {makeID} from '@kashalot-web/core/dist/Utils/config'
import {useDispatcherRequest} from "@kashalot-web/react/dist";
import {GET_DISPATCHER_ID} from "../DispatcherRequestIds";
import BlankVendorForm from "../../../LocalUICore/VendorForms/BlankVendorForm";
import ChaykaFormComponent from "../../../LocalUICore/VendorForms/ChaykaFormComponent";
import YandexFormComponent from "../../../LocalUICore/VendorForms/YandexFormComponent";
import TaxovichkofFormComponent from "../../../LocalUICore/VendorForms/TaxovichkofFormComponent";
import Zero68FormContainer from "../../../LocalUICore/VendorForms/Zero68FormContainer";


const UPDATE_VENDOR_FORM_REQ_ID = "update=vendor-form-" + makeID(8)


const VendorsSection: FC = (): ReactElement => {
    const {getDispatcherOfficeDetails, dispatcherOffice, createVendorForm, deleteVendorForm, updateVendorForm} = useDispatcher()
    const [status] = useDispatcherRequest(GET_DISPATCHER_ID)
    const [updateFormReqStatus, updateFormReqRemove] = useDispatcherRequest(UPDATE_VENDOR_FORM_REQ_ID)

    const [vendorForms, setVendorForms] = useState<VendorForms | null>(null)

    useEffect(() => {
        if (updateFormReqStatus === "success") {
            getDispatcherOfficeDetails(GET_DISPATCHER_ID)
            updateFormReqRemove()
        } else if (updateFormReqStatus === "error") {
            updateFormReqRemove()
        }
    }, [updateFormReqStatus])

    useEffect(() => {
        if (dispatcherOffice) {
            setVendorForms(dispatcherOffice.vendorForms)
        }
    }, [dispatcherOffice])

    if (!vendorForms) {
        return (
            <Grid
                container
                direction={"column"}
                alignItems={"center"}
            >
                <Grid item><CircularProgress /></Grid>
                <Grid item><Typography>Загрузка...</Typography></Grid>
            </Grid>
        )
    }

    return (
        <>
            {vendorForms.map((vendorForm, index) => {
                if (vendorForm.vendorName === "chayka") {
                    return (
                        <ChaykaFormComponent
                            key={`vendor-form-${index}`}
                            defaultChaykaForm={vendorForm}
                            saveForm={newForm => updateVendorForm(newForm, UPDATE_VENDOR_FORM_REQ_ID)}
                            deleteForm={() => deleteVendorForm(vendorForm.id, vendorForm.legalEntityID || "", UPDATE_VENDOR_FORM_REQ_ID)}
                        />
                    )
                } else if (vendorForm.vendorName === "yandex") {
                    return (
                        <YandexFormComponent
                            key={`vendor-form-${index}`}
                            defaultYandexForm={vendorForm}
                            saveForm={newForm => updateVendorForm(newForm, UPDATE_VENDOR_FORM_REQ_ID)}
                            deleteForm={() => deleteVendorForm(vendorForm.id, vendorForm.legalEntityID || "", UPDATE_VENDOR_FORM_REQ_ID)}
                        />
                    )
                } else if (vendorForm.vendorName === "taxovichkof") {
                    return (
                        <TaxovichkofFormComponent
                            key={`vendor-form-${index}`}
                            defaultTaxovichkofForm={vendorForm}
                            saveForm={newForm => updateVendorForm(newForm, UPDATE_VENDOR_FORM_REQ_ID)}
                            deleteForm={() => deleteVendorForm(vendorForm.id, vendorForm.legalEntityID || "", UPDATE_VENDOR_FORM_REQ_ID)}
                        />
                    )
                } else if (vendorForm.vendorName === "068") {
                    return (
                        <Zero68FormContainer
                            key={`vendor-form-${index}`}
                            defaultForm={vendorForm}
                            onSave={newForm => updateVendorForm(newForm, UPDATE_VENDOR_FORM_REQ_ID)}
                            onDelete={() => deleteVendorForm(vendorForm.id, vendorForm.legalEntityID || "", UPDATE_VENDOR_FORM_REQ_ID)}
                        />
                    )
                }
            })}
            <BlankVendorForm
                createForm={newVendorForm => createVendorForm(newVendorForm, UPDATE_VENDOR_FORM_REQ_ID)}
            />
        </>
    )
}

export default VendorsSection
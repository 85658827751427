import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    CircularProgress, FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {useDispatcher, useDispatcherRequest, useOrganization, useOrganizationRequest} from "@kashalot-web/react/dist";
import InputMask from "react-input-mask";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {useNavigate} from "react-router-dom";

const GET_CLIENTS_REQUEST_ID = "create-employee-makfoq3nm[o2"
const CREATE_REQUEST_ID = "create-employee-makfoq3nm[o2"

const CreateEmployee: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {clientsManager, getClients} = useDispatcher()
    const {createEmployee} = useOrganization()
    const [createStatus, removeCreateRequest] = useOrganizationRequest(CREATE_REQUEST_ID)

    const [getClientsStatus, removeGetClientsRequest] = useDispatcherRequest(GET_CLIENTS_REQUEST_ID)
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        setLoading(getClientsStatus === "pending")
    }, [getClientsStatus])
    useEffect(() => {
        getClients(GET_CLIENTS_REQUEST_ID)
        return () => removeGetClientsRequest()
    }, [])


    const [employeeForm, setEmployeeForm] = useState<EmployeeForm>({
        fio: "",
        email: "",
        phone: "",
        type: EmployeeTypes.CompanyManager,
        companyID: "",
        branchID: "",
    })
    const handleEmployeeFormChange = (param: "fio" | "email" | "phone", value: string) => setEmployeeForm({...employeeForm, [param]: value})
    const handleEmployeeTypeChange = (newTypeID: string | number) => {
        const targetType = [...EmployeeTypes.getCompanyEmployees(), ...EmployeeTypes.getBranchEmployees()].find(type => type.id.toString() === newTypeID.toString())
        if (targetType) {
            setEmployeeForm({...employeeForm, type: targetType})
        }
    }
    const [targetOrganization, setTargetOrganization] = useState<ShortCompany | ShortBranch | null>(null)
    useEffect(() => {
        if (!targetOrganization) {
            setEmployeeForm({...employeeForm, companyID: "", branchID: ""})
        } else if (targetOrganization._type === "short-company") {
            setEmployeeForm({...employeeForm, companyID: targetOrganization.id, branchID: ""})
        } else {
            setEmployeeForm({...employeeForm, companyID: targetOrganization.companyID, branchID: targetOrganization.id})
        }
    }, [targetOrganization])
    useEffect(() => {
        if (employeeForm.branchID) {
            if (employeeForm.type.id === EmployeeTypes.CompanyWorker.id) {
                handleEmployeeTypeChange(EmployeeTypes.BranchWorker.id)
            } else if (employeeForm.type.id === EmployeeTypes.CompanyManager.id) {
                handleEmployeeTypeChange(EmployeeTypes.BranchManager.id)
            } else if (employeeForm.type.id === EmployeeTypes.CompanyDirector.id) {
                handleEmployeeTypeChange(EmployeeTypes.BranchDirector.id)
            }
        } else {
            if (employeeForm.type.id === EmployeeTypes.BranchWorker.id) {
                handleEmployeeTypeChange(EmployeeTypes.CompanyWorker.id)
            } else if (employeeForm.type.id === EmployeeTypes.BranchManager.id) {
                handleEmployeeTypeChange(EmployeeTypes.CompanyManager.id)
            } else if (employeeForm.type.id === EmployeeTypes.BranchDirector.id) {
                handleEmployeeTypeChange(EmployeeTypes.CompanyDirector.id)
            }
        }
    }, [employeeForm.branchID])


    const validateForm = () => {
        return employeeForm.fio && employeeForm.companyID && PhoneNumber.validateWithMask(employeeForm.phone) && createStatus !== "pending" &&
            (employeeForm.type.id === EmployeeTypes.CompanyWorker.id || employeeForm.type.id === EmployeeTypes.BranchWorker.id || employeeForm.email)

    }
    const onCreateEmployee = () => {
        createEmployee(employeeForm, CREATE_REQUEST_ID)
    }
    useEffect(() => {
        if (createStatus === "success") {
            removeCreateRequest()
            navigate(-1)
        }
    }, [createStatus])


    return (
        <Grid
            item container
            direction={"column"}
            spacing={2}
        >
            <Grid item><Typography variant="h5">Тип сотрудника</Typography></Grid>
            <Grid item><FormControl>
                <InputLabel>Тип клиента</InputLabel>
                <Select
                    label="Тип сотрудника"
                    value={employeeForm.type.id}
                    onChange={event => handleEmployeeTypeChange(event.target.value)}
                >
                    {employeeForm.branchID ?
                        EmployeeTypes.getBranchEmployees().map((type, index) => (
                            <MenuItem key={`br-em-${index}`} value={type.id}>{type.label}</MenuItem>
                        )) :
                        EmployeeTypes.getCompanyEmployees().map((type, index) => (
                            <MenuItem key={`com-em-${index}`} value={type.id}>{type.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl></Grid>

            <Grid item><Typography variant="h5">Компания</Typography></Grid>
            <Grid item><Autocomplete
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={clientsManager?.getOrganizations() || []}
                loading={loading}
                value={targetOrganization}
                onChange={(event, value) => setTargetOrganization(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Компания"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            /></Grid>

            <Grid item><Typography variant="h5">Контактные данные</Typography></Grid>
            <Grid item><TextField
                label={"ФИО"}
                value={employeeForm.fio}
                onChange={event => handleEmployeeFormChange("fio", event.target.value)}
            /></Grid>
            {
                (employeeForm.type.id === EmployeeTypes.CompanyManager.id ||
                employeeForm.type.id === EmployeeTypes.CompanyDirector.id ||
                employeeForm.type.id === EmployeeTypes.BranchManager.id ||
                employeeForm.type.id === EmployeeTypes.BranchManager.id) && (
                    <Grid item><TextField
                        label={"Email"}
                        value={employeeForm.email}
                        onChange={event => handleEmployeeFormChange("email", event.target.value)}
                    /></Grid>
                )
            }
            <Grid item>
                <InputMask
                    mask="+7 (999) 999-9999"
                    value={employeeForm.phone ? employeeForm.phone : "+"}
                    onChange={(event) => handleEmployeeFormChange("phone", event.target.value)}
                    children={((inputProps: any) =>
                        <TextField
                            {...inputProps}
                            label={"Номер телефона"}
                            type="tel"
                        />) as unknown as ReactNode
                    }
                /></Grid>
            {createStatus === "error" && <Grid item><Typography color={"error"}>Ошибка при создании</Typography></Grid>}
            <Grid item><Button
                variant={"contained"}
                onClick={onCreateEmployee}
                disabled={!validateForm()}
            >Создать</Button></Grid>
        </Grid>
    )
}

export default CreateEmployee
import {FC, ReactElement} from "react";
import {Grid, TextField} from "@mui/material";

interface IBaseVendorForm {
    vendorForm: AbstractVendorForm
    onChange: (newForm: AbstractVendorForm) => void
}

const BaseVendorForm: FC<IBaseVendorForm> = ({vendorForm, onChange}): ReactElement => {
    return (
        <Grid
            item container
            direction={"column"} wrap={"nowrap"}
        >
            <Grid item>
                <TextField
                    label={"Название"}
                    value={vendorForm.name}
                    onChange={e => onChange({...vendorForm, name: e.target.value})}
                />
            </Grid>
        </Grid>
    )
}

export default BaseVendorForm
import {FC, PropsWithChildren, ReactElement} from "react";
import {grey} from "@mui/material/colors";
import {
    AppBar, AppBarProps, Button,
    Container,
    CssBaseline,
    FormControl,
    Grid,
    IconButton, InputLabel, MenuItem,
    Paper, Select, SxProps, TextField, Theme,
    Toolbar,
    Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface IOfficePageSketch {
    title: string
    goBack: () => void
    paperContainer: boolean
    bgcolor?: string
}

const OfficePageSketch: FC<PropsWithChildren<IOfficePageSketch>> = (props): ReactElement => {
    const {title, children, goBack, paperContainer, bgcolor} = props

    return (
        <Grid sx={{bgcolor: grey["50"], minHeight: '100vh', width: '100%', p: '0 20px'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    bgcolor: bgcolor,
                    transition: (theme: Theme) => {
                        return theme.transitions.create(['background-color'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        })
                    }
                }}

            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={goBack}
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                    <Container>
                        <Typography variant="h4">
                            {title}
                        </Typography>
                    </Container>
                </Toolbar>
            </AppBar>
            <Toolbar/>
            {paperContainer ? (
                <Paper
                    component={Grid}
                    container
                    direction="column" wrap={"nowrap"}
                    sx={{p: '12px', width: '100%', maxWidth: '1100px', m: '14px auto'}}
                >
                    {children}
                </Paper>
            ) : (
                <Grid
                    container direction="column" wrap={"nowrap"}
                    sx={{p: '1px 9px', width: '100%', maxWidth: '1100px', m: '14px auto'}}
                >
                    {children}
                </Grid>
            )}
        </Grid>
    )
}

export default OfficePageSketch
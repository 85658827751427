import {FC, ReactElement, useState} from "react"
import {
    AppBar, CssBaseline,
    Divider,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    Theme,
    Toolbar,
    Typography,
    Box
} from "@mui/material"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu'
import {createStyles, makeStyles} from "@mui/styles";
import {grey} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";

interface IDrawerListItem {
    title: string
    onClick: () => void
    isSelected: boolean
    icon: ReactElement
}

const DrawerListItem: FC<IDrawerListItem> = ({title, onClick, isSelected, icon}): ReactElement => {
    return (
        <ListItem button onClick={onClick} selected={isSelected}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={title}/>
        </ListItem>
    )
}


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbarIcon: {
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
}))

interface IMainPageSketch {
    title: string
    selectedSection: string
    sections: Array<{
        title: string
        name: string
        icon: ReactElement
        onClick: () => void
        body: ReactElement
    }>
}

const MainPageSketch: FC<IMainPageSketch> = ({sections, title, selectedSection}): ReactElement => {
    const classes = useStyles()

    const [isOpen, setIsOpen] = useState<boolean>(true)

    const navigate = useNavigate()

    return (
        <Box sx={{display: 'flex', minHeight: 'calc(var(--vh, 1vh) * 100)', bgcolor: grey[50]}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: isOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
                    ml: isOpen ? `${drawerWidth}px` : '0',
                    zIndex: (theme: Theme) => theme.zIndex.drawer + 5,
                    transition: (theme: Theme) => {
                        return theme.transitions.create(['width', 'margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        })
                    }
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setIsOpen(!isOpen)}
                        sx={{display: isOpen ? "none" : "block"}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h4" color="inherit" noWrap>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: isOpen ? drawerWidth : '4.4rem',
                    transition: (theme: Theme) => {
                        return theme.transitions.create(['width', 'margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        })
                    },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isOpen ? drawerWidth : '4.4rem',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        transition: (theme: Theme) => {
                            return theme.transitions.create(['width', 'margin'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            })
                        }
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Grid item container justifyContent="flex-end" alignItems="center" className={classes.toolbarIcon}>
                    <IconButton onClick={() => setIsOpen(!isOpen)}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </Grid>
                <Divider/>
                <List>
                    {
                        sections.map(section => {
                            return (
                                <DrawerListItem
                                    key={`section-${section.name}`}
                                    onClick={section.onClick}
                                    title={section.title}
                                    isSelected={selectedSection === section.name}
                                    icon={section.icon}
                                />
                            )
                        })
                    }
                </List>
            </Drawer>
            <Grid item container direction="column" wrap="nowrap">
                <Toolbar/>
                <Grid item container sx={{p: '12px'}}>
                    {
                        sections.find(section => section.name === selectedSection)?.body
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default MainPageSketch
import {FC, ReactElement, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useOrganization, useOrganizationRequest} from "@kashalot-web/react/dist";
import EmployeeWishlist from "./EmployeeWishlist";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {CircularProgress, Grid, Typography} from "@mui/material";
import {GET_EMPLOYEES_ID} from "../CompanyRequestIDs";
import EmployeeProfile from "../../../LocalUICore/EmployeeProfile";
import {useSnackbar} from "notistack";


const UPDATE_EMPLOYEE_ID = "update-employee-ASGa3sdfAS5DF"


const EmployeePage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const {id} = useParams<{ id: string }>()

    const {getEmployeesList, employeesManager, updateEmployee, reactivateEmployee} = useOrganization()
    const [employeeReqStatus, employeeReqRemove] = useOrganizationRequest(UPDATE_EMPLOYEE_ID)
    const employees = employeesManager?.getAllEmployees() || []
    const targetEmployee = employees.find(employee => employee.id === id)

    useEffect(() => {
        if (employeeReqStatus === "success") {
            enqueueSnackbar("Сотрудник успешно обновлен", {variant: "success"})
            getEmployeesList(GET_EMPLOYEES_ID)
            employeeReqRemove()
        } else if (employeeReqStatus === "error") {
            enqueueSnackbar("Ошибка при обновлении сотрудника", {variant: "error"})
            getEmployeesList(GET_EMPLOYEES_ID)
            employeeReqRemove()
        }
    }, [employeeReqStatus])

    useEffect(() => {
        getEmployeesList(GET_EMPLOYEES_ID)
    }, [])

    return (
        <OfficePageSketch
            title={(targetEmployee?.fio || targetEmployee?.email || "Аккаунт") + (targetEmployee?.active === false ? " (Отключен)" : "")}
            goBack={() => navigate(-1)}
            paperContainer={false}
            bgcolor={targetEmployee?.active === false ? "primary.dark" : "primary.main"}
        >
            {targetEmployee ? (
                <>
                    <EmployeeProfile
                        defaultEmployee={targetEmployee}
                        save={newEmployee => updateEmployee(newEmployee, UPDATE_EMPLOYEE_ID)}
                        reactivate={status => reactivateEmployee(targetEmployee, status ? "1" : "0", UPDATE_EMPLOYEE_ID)}
                    />
                    <EmployeeWishlist employee={targetEmployee} reset={() => getEmployeesList(GET_EMPLOYEES_ID)}/>
                </>
            ) : (
                <Grid
                    container
                    direction={"column"}
                    wrap={"nowrap"}
                    alignItems={"center"}
                >
                    <CircularProgress />
                    <Typography>Загрузка...</Typography>
                </Grid>
            )}
        </OfficePageSketch>
    )
}

export default EmployeePage
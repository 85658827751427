import {FC, ReactElement, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAdmin, useAdminRequest} from "@kashalot-web/react/dist";
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import OfficePageSketch from "../../../lab/OfficePageSketch";
import CompanyQuestionnaire from "./CompanyQuestionnaire";
import BranchQuestionnaire from "./BranchQuestionnaire";

const GET_CLIENT_REQ_ID = "get-client-" + makeID(8)

const ClientPage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {id} = useParams<{ id: string }>()

    const {getClientByID, client} = useAdmin()
    const [getClientStatus] = useAdminRequest(GET_CLIENT_REQ_ID)
    const reset = () => id && getClientByID(id, GET_CLIENT_REQ_ID)

    useEffect(() => {
        reset()
    }, [])

    return (
        <OfficePageSketch
            title={"Клиент"}
            goBack={() => navigate(-1)}
            paperContainer={false}
        >
            {getClientStatus === "pending" && <>Загрузка...</>}
            {getClientStatus !== "pending" && client?._type === "company" && (
                <CompanyQuestionnaire reset={reset} company={client} />
            )}
            {getClientStatus !== "pending" && client?._type === "branch" && (
                <BranchQuestionnaire reset={reset} branch={client} />
            )}
        </OfficePageSketch>
    )
}

export default ClientPage
import {FC, ReactElement} from "react";
import {Grid, TextField} from "@mui/material";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import PaperContainer from "../../lab/Components/PaperContainer";
import Zero68FormComponent, {IZero68FormComponent} from "./Zero68FormComponent";

const Zero68Icon = (props: any) => {
    return (
        <Grid item {...props}>
            <img
                width={50}
                src={TaxiParks.Zero68.logo}
            />
        </Grid>
    )
}

const Zero68FormContainer: FC<IZero68FormComponent> = (props): ReactElement => {
    return (
        <PaperContainer
            title={"068 " + `(${props?.defaultForm?.name || ""})`}
            Icon={Zero68Icon}
            direction={"column"}
            wrap={"nowrap"}
            container
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Zero68FormComponent {...props}/>
        </PaperContainer>
    )
}

export default Zero68FormContainer
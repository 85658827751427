import {FC, ReactElement, useEffect} from "react"
import MainPageSketch from "../../../lab/MainPageSketch"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import GroupsIcon from '@mui/icons-material/Groups';
import {useDispatcher, useProfile} from "@kashalot-web/react/dist"
import {useNavigate} from "react-router-dom"
import FinancesSection from "./FinancesSection"
import VendorsSection from "./VendorsSection"
import GroupsSection from "./GroupsSection";
import ClientsSection from "./ClientsSection"
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import ReceiptsSection from "./ReceiptsSection";
import {GET_DISPATCHER_ID} from "../DispatcherRequestIds";
import LegalEntitiesSection from "./LegalEntitiesSection";
import TransactionsSections from "./TransactionsSections";

interface IMainDispatcherPage {
    section: "main" | "clients" | "reports" | "vendors" | "groups" | "receipts" | "legal_entities" | "transactions"
}

const MainDispatcherPage: FC<IMainDispatcherPage> = ({section}): ReactElement => {
    const {logout} = useProfile()
    const navigate = useNavigate()
    const {getDispatcherOfficeDetails, dispatcherOffice} = useDispatcher()
    useEffect(() => {
        getDispatcherOfficeDetails(GET_DISPATCHER_ID)
    }, [])

    return (
        <MainPageSketch
            title={dispatcherOffice?.name || "Офис"}
            sections={[
                {
                    title: "Профиль",
                    name: "legal_entities",
                    icon: <AccountBalanceIcon/>,
                    onClick: () => navigate('/legal_entities'),
                    body: <LegalEntitiesSection/>,
                },
                {
                    title: "Перевозчики",
                    name: "vendors",
                    icon: <LocalTaxiIcon/>,
                    onClick: () => navigate('/vendors'),
                    body: <VendorsSection/>,
                },
                {
                    title: "Группы",
                    name: "groups",
                    icon: <GroupsIcon/>,
                    onClick: () => navigate('/groups'),
                    body: <GroupsSection/>,
                },
                {
                    title: "Клиенты",
                    name: "clients",
                    icon: <ContactPhoneIcon/>,
                    onClick: () => navigate('/clients'),
                    body: <ClientsSection/>,
                },
                {
                    title: "Транзакции",
                    name: "transactions",
                    icon: <MonetizationOnIcon/>,
                    onClick: () => navigate('/transactions'),
                    body: <TransactionsSections/>,
                },
                {
                    title: "Отчеты",
                    name: "reports",
                    icon: <MonetizationOnIcon/>,
                    onClick: () => navigate('/reports'),
                    body: <FinancesSection/>,
                },
                // {
                //     title: "Счета",
                //     name: "receipts",
                //     icon: <ReceiptIcon/>,
                //     onClick: () => navigate('/receipts'),
                //     body: <ReceiptsSection/>,
                // },
                {
                    title: "Выход",
                    name: "exit",
                    icon: <ExitToAppIcon/>,
                    onClick: () => logout(),
                    body: <></>,
                }
            ]}
            selectedSection={section}
        />
    )
}

export default MainDispatcherPage
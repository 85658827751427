import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import PaperContainer from "../../lab/Components/PaperContainer";
import AddIcon from "@mui/icons-material/Add";
import {Autocomplete, Box, Button, Grid, TextField} from "@mui/material";
import InputMask from "react-input-mask";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import TaxiClasses from "@kashalot-web/core/dist/TaxiInfo/TaxiClasses"
import Zero68FormComponent from "./Zero68FormComponent";
import RequisitesForm from "../RequisitesForm";

interface IBlankVendorForm {
    createForm: (newVendorForm: VendorForm) => void
    createStatus?: "pending" | "error" | "success"
}

const BlankVendorForm: FC<IBlankVendorForm> = ({createForm, createStatus}): ReactElement => {
    const [vendorForm, setVendorForm] = useState<VendorForm | null>(null)
    const handleTaxiPark = (taxiPark: TaxiPark | null) => {
        if (!taxiPark) {
            setVendorForm(null)
            return
        }
        if (taxiPark.id === TaxiParks.CHAYKA.id) {
            setVendorForm({
                id: "",
                name: "",
                active: true,
                vendor: TaxiParks.CHAYKA,
                carClasses: TaxiClasses.getAllClasses(),
                vendorName: "chayka",
                token: "",
                legalEntityID: "",
                legalEntity: null
            })
        } else if (taxiPark.id === TaxiParks.YANDEX_TAXI.id) {
            setVendorForm({
                id: "",
                name: "",
                active: true,
                vendor: TaxiParks.YANDEX_TAXI,
                carClasses: TaxiClasses.getAllClasses(),
                vendorName: "yandex",
                token: "",
                clientID: "",
                legalEntityID: "",
                legalEntity: null
            })
        } else if (taxiPark.id === TaxiParks.TAXOVICHKOF.id) {
            setVendorForm({
                id: "",
                name: "",
                active: true,
                vendor: TaxiParks.TAXOVICHKOF,
                carClasses: TaxiClasses.getAllClasses(),
                vendorName: "taxovichkof",
                phone: "",
                phoneCode: "",
                cashCardID: "",
                cashlessCardID: "",
                secretWord: "",
                legalEntityID: "",
                legalEntity: null
            })
        } else if (taxiPark.id === TaxiParks.Zero68.id) {
            setVendorForm({
                id: '',
                name: '',
                active: true,
                vendor: TaxiParks.Zero68,
                carClasses: TaxiClasses.getAllClasses(),
                vendorName: '068',
                login: '',
                pass: '',
                url: '',
                legalEntityID: "",
                legalEntity: null
            })
        }
    }
    const onCreateVendorForm = (vendorFormCustom?: VendorForm) => {
        if (vendorFormCustom) {
            createForm(vendorFormCustom)
        } else if (vendorForm) {
            createForm(vendorForm)
        }
    }

    useEffect(() => {
        if (createStatus === "success" || createStatus === "error") {
            setVendorForm(null)
        }
    }, [createStatus])

    return (
        <PaperContainer
            Icon={AddIcon}
            title={"Добавить перевозчика"}
            item container
            direction={"column"}
            wrap={"nowrap"}
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item>
                <Autocomplete
                    sx={{width: 220}}
                    value={vendorForm?.vendor || null}
                    onChange={(_, option) => handleTaxiPark(option)}
                    options={[TaxiParks.CHAYKA, TaxiParks.YANDEX_TAXI, TaxiParks.TAXOVICHKOF, TaxiParks.Zero68]}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={option.logo}
                                alt=""
                            />
                            {option.name}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Вебрите таксопарк"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            autoComplete={"off"}
                        />
                    )}
                />
            </Grid>
            {vendorForm && vendorForm.vendorName !== "068" && (
                <Grid item><TextField
                    label={"Название"}
                    value={vendorForm.name}
                    onChange={event => setVendorForm({...vendorForm, name: event.target.value})}
                /></Grid>
            )}
            {vendorForm?.vendorName === "chayka" && (
                <Grid item><TextField
                    label={"Токен"}
                    value={vendorForm.token}
                    onChange={event => setVendorForm({...vendorForm, token: event.target.value})}
                /></Grid>
            )}
            {vendorForm?.vendorName === "yandex" && (<>
                <Grid item><TextField
                    label={"Token"}
                    autoComplete={'off'}
                    value={vendorForm.token}
                    onChange={event => setVendorForm({...vendorForm, token: event.target.value})}
                /></Grid>
                <Grid item><TextField
                    label={"ClientID"}
                    autoComplete={'off'}
                    value={vendorForm.clientID}
                    onChange={event => setVendorForm({...vendorForm, clientID: event.target.value})}
                /></Grid>
            </>)}
            {vendorForm?.vendorName === "taxovichkof" && (<>
                <Grid item container spacing={2}>
                    <Grid item><InputMask
                        mask="+7 (999) 999-9999"
                        value={vendorForm.phone ? vendorForm.phone : "+"}
                        onChange={event => setVendorForm({...vendorForm, phone: event.target.value.match(/\d/g)?.join("") || ""})}
                        children={((inputProps: any) =>
                            <TextField
                                {...inputProps}
                                autoComplete={'off'}
                                label={"Номер телефона"}
                                type="tel"
                            />) as unknown as ReactNode
                        }
                    /></Grid>
                    <Grid item><TextField
                        label={"Пароль"}
                        autoComplete={'off'}
                        value={vendorForm.phoneCode}
                        onChange={event => setVendorForm({...vendorForm, phoneCode: event.target.value})}
                    /></Grid>
                </Grid>
                <Grid item><TextField
                    label={"ID налички"}
                    autoComplete={'off'}
                    value={vendorForm.cashCardID}
                    onChange={event => setVendorForm({...vendorForm, cashCardID: event.target.value})}
                /></Grid>
                <Grid item container spacing={2}>
                    <Grid item><TextField
                        label={"ID безнала"}
                        autoComplete={'off'}
                        value={vendorForm.cashlessCardID}
                        onChange={event => setVendorForm({...vendorForm, cashlessCardID: event.target.value})}
                    /></Grid>
                    <Grid item><TextField
                        label={"Секретное слово"}
                        autoComplete={'off'}
                        value={vendorForm.secretWord}
                        onChange={event => setVendorForm({...vendorForm, secretWord: event.target.value})}
                    /></Grid>
                </Grid>
            </>)}
            {vendorForm?.vendorName === "068" && (
                <Grid item>
                    <Zero68FormComponent
                        onSave={new068Form => onCreateVendorForm(new068Form)}
                    />
                </Grid>
            )}



            {vendorForm && vendorForm.vendorName !== "068" && (
                <>
                    <Grid item>
                        <RequisitesForm
                            defaultRequisites={vendorForm.legalEntity}
                            onChangeRequisites={newRequisites => setVendorForm({...vendorForm, legalEntity: {...newRequisites, id: vendorForm.legalEntity?.id || ""}})}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            onClick={() => onCreateVendorForm()}
                        >
                            Создать
                        </Button>
                    </Grid>
                </>
            )}
        </PaperContainer>
    )
}

export default BlankVendorForm
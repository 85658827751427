import {FC, ReactElement, ReactNode, useEffect} from "react";
import {Button, CircularProgress, Grid, Paper, TextField, Typography} from "@mui/material";
import BlankRequisites from "../../../LocalUICore/BlankRequisites";
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist";
import RequisitesForm from "../../../LocalUICore/RequisitesForm";
import {GET_DISPATCHER_ID} from "../DispatcherRequestIds";
import PersonIcon from "@mui/icons-material/Person";
import InputMask from "react-input-mask";
import AddressInput from "../../../lab/Input/AddressInputLab";
import PaperContainer from "../../../lab/Components/PaperContainer";
import TransactionsTable from "../../../LocalUICore/TransactionsTable";


const LEGAL_ENTITY_ACTION_ID = "legal-entity-action-0000"


const LegalEntitiesSection: FC = (): ReactElement => {
    const {dispatcherOffice, createLegalEntity, updateLegalEntity, deleteLegalEntity, getDispatcherOfficeDetails, transactions} = useDispatcher()
    const [legalReqStatus, legalReqRemove] = useDispatcherRequest(LEGAL_ENTITY_ACTION_ID)
    const [getDispatcherStatus] = useDispatcherRequest(GET_DISPATCHER_ID)

    useEffect(() => {
        if (legalReqStatus === "success" || legalReqStatus === "error") {
            legalReqRemove()
            getDispatcherOfficeDetails(GET_DISPATCHER_ID)
        }
    }, [legalReqStatus])

    if (!dispatcherOffice || getDispatcherStatus === "pending") {
        return (
            <Grid container direction={"column"} wrap={"nowrap"} alignItems={"center"}>
                <Grid item>
                    <CircularProgress />
                </Grid>
                <Grid item>
                    <Typography>Загрузка...</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid
            container
            direction={"column"}
            wrap={"nowrap"}
            alignItems={"center"}
            spacing={2}
        >
            <Grid item container sx={{mb: '-18px'}}>
                <PaperContainer
                    title={"Профиль"}
                    Icon={PersonIcon}
                    item
                    container
                    sx={{p: '16px 0'}}
                >
                    <Grid item>
                        <Grid container spacing={2} direction={"column"}>
                            <Grid item>
                                <TextField
                                    label={"Название"}
                                    value={dispatcherOffice.name}
                                    inputProps={{ readOnly: true }}
                                />
                            </Grid>
                            <Grid item><InputMask
                                mask="+7 (999) 999-9999"
                                value={dispatcherOffice.phoneNumber ? dispatcherOffice.phoneNumber : "+"}
                                children={((inputProps: any) =>
                                    <TextField
                                        {...inputProps}
                                        label={"Номер телефона"}
                                        type="tel"
                                        inputProps={{ readOnly: true }}
                                    />) as unknown as ReactNode
                                }
                            /></Grid>
                            {dispatcherOffice.balance !== undefined && (
                                <Grid item>
                                    <TextField
                                        label={"Баланс"}
                                        value={dispatcherOffice.balance}
                                        inputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </PaperContainer>
            </Grid>

            {dispatcherOffice.legalEntities.map(legalEntity => {
                return (
                    <Grid item key={legalEntity.id}>
                        <Grid container>
                            <Paper component={Grid} item sx={{p: '16px'}}>
                                <RequisitesForm
                                    defaultRequisites={legalEntity}
                                    onSaveRequisites={newRequisites => updateLegalEntity({...newRequisites, id: legalEntity.id}, LEGAL_ENTITY_ACTION_ID)}
                                    onDelete={() => deleteLegalEntity(legalEntity.id, LEGAL_ENTITY_ACTION_ID)}
                                    actionType={"save"}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )
            })}
            <Grid item>
                <BlankRequisites
                    onCreate={newRequisites => createLegalEntity({...newRequisites, id: ''}, LEGAL_ENTITY_ACTION_ID)}
                />
            </Grid>
        </Grid>
    )
}

export default LegalEntitiesSection
import {FC, ReactElement, useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import StyledButton from "./StyledButton";
import emptyRequisites from "./EmptyRequisites";

export const validateRequisitesForm = (srcRequisites: Requisites, newRequisites: Requisites) => {
    return srcRequisites.bankName !== newRequisites.bankName
        || srcRequisites.bankBic !== newRequisites.bankBic
        || srcRequisites.bankAccountNumber !== newRequisites.bankAccountNumber
        || srcRequisites.correspondentBankAccountNumber !== newRequisites.correspondentBankAccountNumber
        || srcRequisites.legalName !== newRequisites.legalName
        || srcRequisites.legalAddress !== newRequisites.legalAddress
        || srcRequisites.zipCode !== newRequisites.zipCode
        || srcRequisites.inn !== newRequisites.inn
        || srcRequisites.kpp !== newRequisites.kpp
        || srcRequisites.directorName !== newRequisites.directorName
        || srcRequisites.accountantName !== newRequisites.accountantName
        || srcRequisites.taxType !== newRequisites.taxType
}

interface IRequisitesForm {
    defaultRequisites: Requisites | null
    onChangeRequisites?: (newRequisites: Requisites) => void
    onSaveRequisites?: (newRequisites: Requisites) => void
    onDelete?: () => void
    actionType?: "create" | "save"
}

const RequisitesForm: FC<IRequisitesForm> = ({
                                                 defaultRequisites,
                                                 onSaveRequisites,
                                                 onDelete,
                                                 actionType,
                                                 onChangeRequisites
                                             }): ReactElement => {
    const [requisites, setRequisites] = useState<Requisites>(defaultRequisites || emptyRequisites)

    useEffect(() => {
        if (onChangeRequisites) {
            onChangeRequisites(requisites)
        }
    }, [requisites])

    useEffect(() => {
        if (!onChangeRequisites) {
            setRequisites(defaultRequisites || emptyRequisites)
        }
    }, [defaultRequisites])

    const validate = () => {
        if (defaultRequisites) {
            return requisites.bankName !== defaultRequisites.bankName
                || requisites.bankBic !== defaultRequisites.bankBic
                || requisites.bankAccountNumber !== defaultRequisites.bankAccountNumber
                || requisites.correspondentBankAccountNumber !== defaultRequisites.correspondentBankAccountNumber
                || requisites.legalName !== defaultRequisites.legalName
                || requisites.legalAddress !== defaultRequisites.legalAddress
                || requisites.zipCode !== defaultRequisites.zipCode
                || requisites.inn !== defaultRequisites.inn
                || requisites.kpp !== defaultRequisites.kpp
                || requisites.directorName !== defaultRequisites.directorName
                || requisites.accountantName !== defaultRequisites.accountantName
                || requisites.taxType !== defaultRequisites.taxType
        }
        return requisites.bankName !== emptyRequisites.bankName
            || requisites.bankBic !== emptyRequisites.bankBic
            || requisites.bankAccountNumber !== emptyRequisites.bankAccountNumber
            || requisites.correspondentBankAccountNumber !== emptyRequisites.correspondentBankAccountNumber
            || requisites.legalName !== emptyRequisites.legalName
            || requisites.legalAddress !== emptyRequisites.legalAddress
            || requisites.zipCode !== emptyRequisites.zipCode
            || requisites.inn !== emptyRequisites.inn
            || requisites.kpp !== emptyRequisites.kpp
            || requisites.directorName !== emptyRequisites.directorName
            || requisites.accountantName !== emptyRequisites.accountantName
            || requisites.taxType !== emptyRequisites.taxType
    }

    return (
        <Grid item>
            <Grid container direction={"column"} wrap={"nowrap"} spacing={2}>
                <Grid item>
                    <TextField
                        sx={{width: 616}}
                        label={"Название банка"}
                        autoComplete={'off'}
                        value={requisites.bankName}
                        onChange={e => setRequisites({...requisites, bankName: e.target.value})}
                        inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                    />
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"БИК банка"}
                            autoComplete={'off'}
                            value={requisites.bankBic}
                            onChange={e => setRequisites({...requisites, bankBic: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"Номер счета банка"}
                            autoComplete={'off'}
                            value={requisites.bankAccountNumber}
                            onChange={e => setRequisites({...requisites, bankAccountNumber: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item>
                        <TextField
                            sx={{width: 616}}
                            label={"Юр лицо компании"}
                            autoComplete={'off'}
                            value={requisites.legalName}
                            onChange={e => setRequisites({...requisites, legalName: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                    <Grid item>
                        {(!!onSaveRequisites || !!onChangeRequisites) ? (
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Тип налогообложения</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Тип налогообложения"
                                    value={requisites.taxType}
                                    onChange={event => {
                                        const value = event.target.value
                                        if (value === "general") {
                                            setRequisites({...requisites, taxType: "general"})
                                        } else if (value === "simple") {
                                            setRequisites({...requisites, taxType: "simple"})
                                        }
                                    }}
                                    sx={{width: 300}}
                                >
                                    <MenuItem value={"general"}>Общий</MenuItem>
                                    <MenuItem value={"simple"}>Упрощенный</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField
                                sx={{width: 300}}
                                label={"Тип налогообложения"}
                                value={(() => {
                                    if (requisites.taxType === "general") {
                                        return "Общий"
                                    } else if (requisites.taxType === "simple") {
                                        return "Упрощенный"
                                    }
                                })()}
                                inputProps={{readOnly: true}}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item>
                        <TextField
                            sx={{width: 616}}
                            label={"Юр адрес компании"}
                            autoComplete={'off'}
                            value={requisites.legalAddress}
                            onChange={e => setRequisites({...requisites, legalAddress: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"Почтовый индекс"}
                            autoComplete={'off'}
                            value={requisites.zipCode}
                            onChange={e => setRequisites({...requisites, zipCode: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"№ счета компании в банке"}
                            autoComplete={'off'}
                            value={requisites.correspondentBankAccountNumber}
                            onChange={e => setRequisites({
                                ...requisites,
                                correspondentBankAccountNumber: e.target.value
                            })}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"ИНН"}
                            autoComplete={'off'}
                            value={requisites.inn}
                            onChange={e => setRequisites({...requisites, inn: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"КПП"}
                            autoComplete={'off'}
                            value={requisites.kpp}
                            onChange={e => setRequisites({...requisites, kpp: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"Руководитель"}
                            autoComplete={'off'}
                            value={requisites.directorName}
                            onChange={e => setRequisites({...requisites, directorName: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            sx={{width: 300}}
                            label={"Бухгалтер"}
                            autoComplete={'off'}
                            value={requisites.accountantName}
                            onChange={e => setRequisites({...requisites, accountantName: e.target.value})}
                            inputProps={{readOnly: !onSaveRequisites && !onChangeRequisites}}
                        />
                    </Grid>
                </Grid>

                {actionType && (
                    <Grid item container justifyContent={"center"} spacing={2}>
                        {!!onSaveRequisites && (
                            <Grid item>
                                <StyledButton
                                    onClick={() => onSaveRequisites(requisites)}
                                    disabled={!validate()}
                                >
                                    {actionType === "create" && (<>Создать</>)}
                                    {actionType === "save" && (<>Сохранить</>)}
                                </StyledButton>
                            </Grid>
                        )}
                        {!!onDelete && (
                            <Grid item>
                                <StyledButton onClick={onDelete}>
                                    {actionType === "create" && (<>Отменить</>)}
                                    {actionType === "save" && (<>Удалить</>)}
                                </StyledButton>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default RequisitesForm
import {FC, ReactElement, useEffect} from "react";
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist";
import {Button, Grid, Paper, Typography} from "@mui/material";
import MaterialTable, {MTableToolbar} from "@material-table/core";
import {useNavigate} from "react-router-dom";
import {GET_DISPATCHER_ID} from "../DispatcherRequestIds";
import StyledButton from "../../../LocalUICore/StyledButton";

const DELETE_GROUP_REQ_ID = "delete-group-req-alksdmfalnk"

const mapGroupsForTable = (groups: Array<DispatcherGroup>) => {
    return groups.map(group => {
        return {
            id: group.id,
            name: group.name
        }
    })
}

const GroupsSection: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {getDispatcherOfficeDetails, dispatcherOffice, deleteGroup} = useDispatcher()
    const [getDispatcherReqStatus] = useDispatcherRequest(GET_DISPATCHER_ID)

    const [status] = useDispatcherRequest(DELETE_GROUP_REQ_ID)

    useEffect(() => {
        if (status === "success" || status === "error") {
            getDispatcherOfficeDetails(GET_DISPATCHER_ID)
        }
    }, [status])

    useEffect(() => {
        getDispatcherOfficeDetails(GET_DISPATCHER_ID)
    }, [])

    return (
        <MaterialTable
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Группы</Typography>}
            isLoading={getDispatcherReqStatus === "pending" || status === "pending"}
            data={dispatcherOffice ? mapGroupsForTable(dispatcherOffice.groups) : []}
            columns={[
                {title: "Название", field: "name"}
            ]}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true
            }}
            onRowClick={(event, data) => {
                if (data && data.id) {
                    navigate(`/group/${data.id}`)
                }
            }}
            editable={{
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        deleteGroup(oldData.id, DELETE_GROUP_REQ_ID)
                        resolve(true)
                    })
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    sx={{p: '9px'}}
                                >
                                    <StyledButton onClick={() => navigate('/create_group')}>
                                        Создать группу
                                    </StyledButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
        />
    )
}

export default GroupsSection
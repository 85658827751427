import {FC, ReactElement, useEffect} from "react";
import CompanyBelongDispatcher from "../../../lab/Components/CompanyBelongDispatcher";
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import {useAdmin, useAdminRequest} from "@kashalot-web/react/dist";


const GET_DISPATCHER_OFFICES_ID = "get-dispatcher-offices-" + makeID(8)
const ASSIGN_DISPATCHER_OFFICE_ID = "assign-dispatcher-office-" + makeID(8)


interface ICompanyBelongDispatcherContainer {
    defaultCompany: Company | Branch
    reset: () => void
}

const CompanyBelongDispatcherContainer: FC<ICompanyBelongDispatcherContainer> = ({defaultCompany, reset}): ReactElement => {
    const {getDispatcherOffices, assignDispatcherOffice, dispatcherOffices} = useAdmin()

    useEffect(() => {
        getDispatcherOffices(GET_DISPATCHER_OFFICES_ID)
    }, [])

    const save = (dispatcher_office_id: string) => {
        if (defaultCompany._type === "branch") {
            assignDispatcherOffice("branch", defaultCompany.id, dispatcher_office_id, ASSIGN_DISPATCHER_OFFICE_ID)
        } else {
            assignDispatcherOffice("company", defaultCompany.id, dispatcher_office_id, ASSIGN_DISPATCHER_OFFICE_ID)
        }
    }

    const [assignStatus, assignRemove] = useAdminRequest(ASSIGN_DISPATCHER_OFFICE_ID)
    useEffect(() => {
        if (assignStatus === "success" || assignStatus === "error") {
            assignRemove()
            reset()
        }
    }, [assignStatus])

    return (
        <CompanyBelongDispatcher
            defaultCompany={defaultCompany}
            dispatcherOffices={dispatcherOffices}
            save={save}
        />
    )
}

export default CompanyBelongDispatcherContainer
import {FC, ReactElement} from "react";
import {useMap} from "@kashalot-web/react/dist";
import BranchProfile from "../../../lab/Components/BranchProfile";

interface IBranchProfileContainer {
    branch: Branch
}

const BranchProfileContainer: FC<IBranchProfileContainer> = ({branch}): ReactElement => {
    const {suggestionsRequests, pushSuggestionRequest} = useMap()

    return (
        <BranchProfile
            branch={branch}
            suggestionsRequests={suggestionsRequests}
            pushSuggestionRequest={pushSuggestionRequest}
        />
    )
}

export default BranchProfileContainer
import {FC, ReactElement} from "react";
import CompanyBelongDispatcherContainer from "./CompanyBelongDispatcherContainer";
import BranchProfileContainer from "./BranchProfileContainer";
import CompanyRequisitesContainer from "./CompanyRequisitesContainer";

interface IBranchQuestionnaire {
    branch: Branch
    reset: () => void
}

const BranchQuestionnaire: FC<IBranchQuestionnaire> = ({reset, branch}): ReactElement => {
    return (
        <>
            <CompanyBelongDispatcherContainer reset={reset} defaultCompany={branch} />
            <BranchProfileContainer branch={branch} />
            <CompanyRequisitesContainer reset={reset} company={branch} />
        </>
    )
}

export default BranchQuestionnaire
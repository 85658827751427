import {FC, ReactElement, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import OfficePageSketch from "../../../lab/OfficePageSketch";
import {useDispatcher, useDispatcherRequest, useOrganization, useOrganizationRequest} from "@kashalot-web/react/dist";
import EmployeeQuestionnaire from "./EmployeeQuestionnaire";
import CompanyQuestionnaire from "./CompanyQuestionnaire";
import BranchQuestionnaire from "./BranchQuestionnaire";
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {CircularProgress, Grid, Typography} from "@mui/material";

const GET_CLIENT_BY_ID_REQ_ID = "get-client-by-id-req-asdmfq3oqn2"

const makeTitle = (client: Employee | Company | Branch | null) => {
    if (!client) {
        return "Клиент"
    }
    if (client._type === "employee") {
        if (client.type.id === EmployeeTypes.BranchWorker.id || client.type.id === EmployeeTypes.CompanyWorker.id) {
            return client.fio
        } else {
            return `${client.fio} (${client.email})`
        }
    }
    if (client._type === "company") {
        return client.name
    }
    if (client._type === "branch") {
        return client.name
    }
}

const ClientPage: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {id} = useParams<{ id: string }>()
    const {getClientByID, client, clientTransactions} = useDispatcher()
    const {createLegal, deleteLegal, updateLegal} = useOrganization()

    const [status, removeRequest] = useDispatcherRequest(GET_CLIENT_BY_ID_REQ_ID)

    useEffect(() => {
        if (id) {
            getClientByID(id, GET_CLIENT_BY_ID_REQ_ID)
        }
        return () => removeRequest()
    }, [])

    return (
        <OfficePageSketch
            title={makeTitle(client) || "Клиент"}
            goBack={() => navigate(-1)}
            paperContainer={false}
        >
            {status === "pending" && (
                <Grid container direction={"column"} wrap={"nowrap"} alignItems={"center"}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                    <Grid item>
                        <Typography>Загрузка...</Typography>
                    </Grid>
                </Grid>
            )}
            {status === "error" && (<>ошибка</>)}
            {(status === "success" || !status) && client && (
                (client._type === "employee" && <EmployeeQuestionnaire employee={client} reset={() => id && getClientByID(id, GET_CLIENT_BY_ID_REQ_ID)} />) ||
                ((client._type === "company" || client._type === "branch") && (
                    <CompanyQuestionnaire
                        company={client}
                        transactions={clientTransactions || []}
                        reset={() => id && getClientByID(id, GET_CLIENT_BY_ID_REQ_ID)}
                    />
                ))
            )}
        </OfficePageSketch>
    )
}

export default ClientPage
import {FC, ReactElement} from "react";
import CompanyProfileContainer from "./CompanyProfileContainer";
import CompanyBelongDispatcherContainer from "./CompanyBelongDispatcherContainer";
import CompanyRequisitesContainer from "./CompanyRequisitesContainer";

interface ICompanyQuestionnaire {
    company: Company
    reset: () => void
}

const CompanyQuestionnaire: FC<ICompanyQuestionnaire> = ({company, reset}): ReactElement => {
    return (
        <>
            <CompanyBelongDispatcherContainer reset={reset} defaultCompany={company} />
            <CompanyProfileContainer reset={reset} company={company} />
            <CompanyRequisitesContainer reset={reset} company={company} />
        </>
    )
}

export default CompanyQuestionnaire
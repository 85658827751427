import {FC, ReactElement, useEffect, useState} from "react"
import {GET_CLIENTS_ID, GET_DISPATCHER_ID} from "../DispatcherRequestIds"
import {useDispatcher, useDispatcherRequest} from "@kashalot-web/react/dist"
import {Autocomplete, Grid, Paper, TextField, Typography} from "@mui/material"
import MaterialTable, {MTableToolbar} from "@material-table/core"
import {DatePicker, LocalizationProvider} from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import ruLocale from "date-fns/locale/ru"
import moment from "moment"
import StyledButton from "../../../LocalUICore/StyledButton"
import {makeID} from "@kashalot-web/core/dist/Utils/config"


const GET_CLIENT_TRANSACTIONS_REQ_ID = "GET_CLIENT_TRANSACTIONS_REQ_" + makeID(8)
const GET_TRANSACTIONS_REQ_ID = "GET_TRANSACTIONS_REQ_" + makeID(8)


const makeTableData = (transactions: Array<Transaction>) => {
    return transactions.map(transaction => {
        return {
            date: transaction.time,
            sum: transaction.sum,
            comment: transaction.comment,
            sender: transaction.senderLegalName,
            receiver: transaction.receiverLegalName,
        }
    })
}


const TransactionsSections: FC = (): ReactElement => {
    const {
        getClients, clientsManager,
        transactions, getTransactions,
        clientTransactions, getClientTransactions,
        dispatcherOffice, getDispatcherOfficeDetails
    } = useDispatcher()

    useEffect(() => {
        getClients(GET_CLIENTS_ID)
        getDispatcherOfficeDetails(GET_DISPATCHER_ID)
    }, [])

    const getClientsReq = useDispatcherRequest(GET_CLIENTS_ID)
    const getClientTransactionsReq = useDispatcherRequest(GET_CLIENT_TRANSACTIONS_REQ_ID)
    const getTransactionsReq = useDispatcherRequest(GET_TRANSACTIONS_REQ_ID)
    const getDispatcherReq = useDispatcherRequest(GET_DISPATCHER_ID)

    useEffect(() => {
        if (getDispatcherReq[0] === "success" || getDispatcherReq[0] === "error") {
            getDispatcherReq[1]()
            setTransactionsFilters({...transactionsFilters, organizationID: dispatcherOffice?.id || ""})
        }
    }, [getDispatcherReq[0]])


    const [transactionsFilters, setTransactionsFilters] = useState<FiltersTransactions>({
        type: "dispatcher", organizationID: '',
        dateStart: moment().subtract('1', 'months').format('DD.MM.YYYY'),
        dateEnd: moment().format('DD.MM.YYYY'),
    })

    useEffect(() => {
        if (transactionsFilters.type === "company" || transactionsFilters.type === "branch") {
            getClientTransactions(transactionsFilters, GET_CLIENT_TRANSACTIONS_REQ_ID)
        } else {
            getTransactions(transactionsFilters, GET_TRANSACTIONS_REQ_ID)
        }
    }, [transactionsFilters])


    const isLoading = (): boolean => {
        if ((transactionsFilters.type === "company" || transactionsFilters.type === "branch") && getClientTransactionsReq[0] === "pending") {
            return true
        }
        if (transactionsFilters.type === "dispatcher" && getTransactionsReq[0] === "pending") {
            return true
        }
        return false
    }


    const data = () => {
        if (isLoading()) {
            return []
        } else if (transactionsFilters.type === "company" || transactionsFilters.type === "branch") {
            return makeTableData(clientTransactions || [])
        } else {
            return makeTableData(transactions || [])
        }
    }


    return (
        <MaterialTable
            isLoading={isLoading()}
            style={{width: "100%"}}
            title={<Typography variant={"h4"}>Транзакции</Typography>}
            columns={[
                {title: "Дата", field: "date"},
                {title: "Сумма", field: "sum"},
                {title: "Описание", field: "comment"},
                {title: "Отправитель", field: "sender"},
                {title: "Получатель", field: "receiver"}
            ]}
            data={data()}
            options={{
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 50],
                filtering: true
            }}
            components={{
                Toolbar: props => {
                    return (
                        <Grid container direction="column" wrap="nowrap">
                            <MTableToolbar {...props} />
                            <Grid item container sx={{p: '9px'}}>
                                <Paper
                                    component={Grid}
                                    item container
                                    sx={{p: '18px 14px'}}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        spacing={2}
                                    >
                                                <Grid item>
                                                    <Autocomplete
                                                        sx={{width: 300}}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        getOptionLabel={(option) => option.name}
                                                        options={clientsManager?.getOrganizations() || []}
                                                        value={(() => {
                                                            if (!transactionsFilters.organizationID || !clientsManager) {
                                                                return undefined
                                                            }
                                                            return clientsManager.getOrganizations().find(client => {
                                                                return transactionsFilters.organizationID === client.id
                                                            })
                                                        })()}
                                                        onChange={(_, value) => {
                                                            if (!value) {
                                                                setTransactionsFilters({
                                                                    ...transactionsFilters,
                                                                    type: "dispatcher",
                                                                    organizationID: dispatcherOffice?.id || ""
                                                                })
                                                            } else if (value._type == "short-company") {
                                                                setTransactionsFilters({
                                                                    ...transactionsFilters,
                                                                    type: "company",
                                                                    organizationID: value.id
                                                                })
                                                            } else {
                                                                setTransactionsFilters({
                                                                    ...transactionsFilters,
                                                                    type: "branch",
                                                                    organizationID: value.id
                                                                })
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Клиенты"
                                                                InputProps={{
                                                                    ...params.InputProps
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                        <Grid item container spacing={2}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                <Grid item>
                                                    <DatePicker
                                                        renderInput={(params) => (
                                                            <TextField
                                                                sx={{width: 260}} {...params}
                                                                label="От"
                                                            />)}
                                                        value={moment(transactionsFilters.dateStart, 'DD.MM.YYYY').toDate()}
                                                        onChange={(newDate: any) => setTransactionsFilters({
                                                            ...transactionsFilters, dateStart: moment(newDate.toISOString()).format('DD.MM.YYYY')
                                                        })}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <DatePicker
                                                        renderInput={(params) => <TextField sx={{width: 260}} {...params}
                                                                                            label="До"/>}
                                                        value={moment(transactionsFilters.dateEnd, 'DD.MM.YYYY').toDate()}
                                                        onChange={(newDate: any) => setTransactionsFilters({
                                                            ...transactionsFilters, dateEnd: moment(newDate.toISOString()).format('DD.MM.YYYY')
                                                        })}
                                                    />
                                                </Grid>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
            }}
        />
    )
}

export default TransactionsSections
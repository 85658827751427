import {FC, ReactElement, useEffect, useState} from "react";
import {
    Box, Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    ModalProps,
    Select,
    TextField,
    Typography
} from "@mui/material";
import PaperContainer from "../../../lab/Components/PaperContainer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    border: 0,
    // boxShadow: 24,
    // p: 4
};

const BuildIcon = (src: string) => (props: any): ReactElement => {
    return (
        <Grid item {...props}>
            <img
                width={50}
                src={src}
            />
        </Grid>
    )
}

const getPercentsTypes = (): Array<{ value: PriceStruct["type"], label: string }> => {
    return [
        {value: "plusPercent", label: "Добавить процентов"},
        {value: "minusPercent", label: "Отнять процентов"},
        {value: "plusSum", label: "Добавить фиксированную сумму"},
        {value: "minusSum", label: "Отнять фиксированную сумму"},
    ]
}

interface IVendorModalContainer extends ModalProps {
    defaultRule: Rule
    save: (rule: Rule) => void
}

const VendorModalContainer: FC<IVendorModalContainer> = ({ defaultRule, save, ...modalProps}): ReactElement => {
    const [rule, setRule] = useState<Rule>(defaultRule)
    const handleRuleValueChange = (paymentType: "cash" | "cashless", orderType: "0" | "15", value: number) =>
        setRule({
            ...rule,
            [paymentType]: {...rule[paymentType], [orderType]: {...rule[paymentType][orderType], value: value}}
        })
    const handleRuleTypeChange = (paymentType: "cash" | "cashless", orderType: "0" | "15", type: PriceStruct["type"]) =>
        setRule({
            ...rule,
            [paymentType]: {...rule[paymentType], [orderType]: {...rule[paymentType][orderType], type: type}}
        })
    useEffect(() => {
        if (modalProps.open) {
            setRule(defaultRule)
        }
    }, [modalProps.open])
    const onSave = () => save(rule)
    const validate = () => {
        return defaultRule.cash["0"].type === rule.cash["0"].type && defaultRule.cash["0"].value === rule.cash["0"].value &&
            defaultRule.cash["15"].type === rule.cash["15"].type && defaultRule.cash["15"].value === rule.cash["15"].value &&
            defaultRule.cashless["0"].type === rule.cashless["0"].type && defaultRule.cashless["0"].value === rule.cashless["0"].value &&
            defaultRule.cashless["15"].type === rule.cashless["15"].type && defaultRule.cashless["15"].value === rule.cashless["15"].value
    }

    return (
        <Modal {...modalProps}>
            <Box sx={style}>
                <PaperContainer
                    // Icon={BuildIcon(rules[index].vendor.logo)}
                    // title={rules[index].vendor.name}
                    container
                    direction={"column"}
                    wrap={"nowrap"}
                    sx={{width: "100%", height: "100%", p: '16px 0'}}
                    spacing={2}
                >
                    <Grid item container alignItems={"center"}>
                        <Grid item>
                            <Typography sx={{minWidth: '6rem'}}>Безнал</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{minWidth: '10rem'}}>Корпоративный</Typography>
                        </Grid>
                        <Grid item><FormControl>
                            <InputLabel>{"Тип"}</InputLabel>
                            <Select
                                value={rule.cashless["0"].type}
                                label={"Тип"}
                                onChange={event => handleRuleTypeChange("cashless", "0", event.target.value as PriceStruct["type"])}
                            >
                                {getPercentsTypes().map((item, ind) =>
                                    <MenuItem key={`row-${0}-col-${ind}`}
                                              value={item.value}> {item.label} </MenuItem>)}
                            </Select>
                        </FormControl></Grid>
                        <Grid item><TextField
                            type="number"
                            variant="outlined"
                            label={"Значение"}
                            value={rule.cashless["0"].value}
                            onChange={(event) => {
                                try {
                                    const num = Number.parseInt(event.target.value)
                                    handleRuleValueChange("cashless", "0", num)
                                } catch (e) {
                                }
                            }}
                        /></Grid>
                    </Grid>

                    <Grid item container alignItems={"center"}>
                        <Grid item>
                            <Typography sx={{minWidth: '6rem'}}>Безнал</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{minWidth: '10rem'}}>Агентский</Typography>
                        </Grid>
                        <Grid item><FormControl>
                            <InputLabel>{"Тип"}</InputLabel>
                            <Select
                                value={rule.cashless["15"].type}
                                label={"Тип"}
                                onChange={event => handleRuleTypeChange("cashless", "15", event.target.value as PriceStruct["type"])}
                            >
                                {getPercentsTypes().map((item, ind) =>
                                    <MenuItem key={`row-${1}-col-${ind}`}
                                              value={item.value}> {item.label} </MenuItem>)}
                            </Select>
                        </FormControl></Grid>
                        <Grid item><TextField
                            type="number"
                            variant="outlined"
                            label={"Значение"}
                            value={rule.cashless["15"].value}
                            onChange={(event) => {
                                try {
                                    const num = Number.parseInt(event.target.value)
                                    handleRuleValueChange("cashless", "15", num)
                                } catch (e) {
                                }
                            }}
                        /></Grid>
                    </Grid>

                    <Grid item container alignItems={"center"}>
                        <Grid item>
                            <Typography sx={{minWidth: '6rem'}}>Наличные</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{minWidth: '10rem'}}>Корпоративный</Typography>
                        </Grid>
                        <Grid item><FormControl>
                            <InputLabel>{"Тип"}</InputLabel>
                            <Select
                                value={rule.cash["0"].type}
                                label={"Тип"}
                                onChange={event => handleRuleTypeChange("cash", "0", event.target.value as PriceStruct["type"])}
                            >
                                {getPercentsTypes().map((item, ind) =>
                                    <MenuItem key={`row-${2}-col-${ind}`}
                                              value={item.value}> {item.label} </MenuItem>)}
                            </Select>
                        </FormControl></Grid>
                        <Grid item><TextField
                            type="number"
                            variant="outlined"
                            label={"Значение"}
                            value={rule.cash["0"].value}
                            onChange={(event) => {
                                try {
                                    const num = Number.parseInt(event.target.value)
                                    handleRuleValueChange("cash", "0", num)
                                } catch (e) {
                                }
                            }}
                        /></Grid>
                    </Grid>

                    <Grid item container alignItems={"center"}>
                        <Grid item>
                            <Typography sx={{minWidth: '6rem'}}>Наличные</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{minWidth: '10rem'}}>Агентский</Typography>
                        </Grid>
                        <Grid item><FormControl>
                            <InputLabel>{"Тип"}</InputLabel>
                            <Select
                                value={rule.cash["15"].type}
                                label={"Тип"}
                                onChange={event => handleRuleTypeChange("cash", "15", event.target.value as PriceStruct["type"])}
                            >
                                {getPercentsTypes().map((item, ind) =>
                                    <MenuItem key={`row-${3}-col-${ind}`}
                                              value={item.value}> {item.label} </MenuItem>)}
                            </Select>
                        </FormControl></Grid>
                        <Grid item><TextField
                            type="number"
                            variant="outlined"
                            label={"Значение"}
                            value={rule.cash["15"].value}
                            onChange={(event) => {
                                try {
                                    const num = Number.parseInt(event.target.value)
                                    handleRuleValueChange("cash", "15", num)
                                } catch (e) {
                                }
                            }}
                        /></Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            onClick={onSave}
                            disabled={validate()}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </PaperContainer>
            </Box>
        </Modal>
    )
}

export default VendorModalContainer
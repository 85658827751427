import {FC, ReactElement, ReactNode} from "react";
import {Grid, Paper, TextField} from "@mui/material";
import PaperContainer from "../lab/Components/PaperContainer";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import InputMask from "react-input-mask";
import PhoneInput from "./PhoneInput";

type Profile = {
    name: string
    phoneNumber: string
    address: string
}

interface IOrganizationProfile {
    profile: Profile
    onSave?: (newProfile: Profile) => void
}

const OrganizationProfile: FC<IOrganizationProfile> = ({profile, onSave}): ReactElement => {

    return (
        <PaperContainer
            title="Профиль"
            Icon={AccountBoxIcon}
            container
            direction={"column"}
            wrap={"nowrap"}
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item>
                <TextField
                    name={"profile-name"}
                    label={"Название"}
                    value={profile.name}
                    inputProps={{ readOnly: !onSave }}
                />
            </Grid>
            <Grid item>
                <PhoneInput
                    label={"Номер телефона"}
                    value={profile.phoneNumber}
                    // onChange={e => setEmployee({...employee, phone: e.target.value})}
                />
            </Grid>
            <Grid item>
                <TextField
                    sx={{width: 300}}
                    name={"profile-address"}
                    label={"Адрес"}
                    value={profile.address}
                    inputProps={{ readOnly: !onSave }}
                />
            </Grid>
        </PaperContainer>
    )
}

export default OrganizationProfile
import {FC, ReactElement} from "react";
import {Button, ButtonProps} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles"
import {primaryDarkerTheme} from "@kashalot-web/ui";

const StyledButton: FC<ButtonProps> = (props): ReactElement => {
    return (
        <ThemeProvider theme={primaryDarkerTheme}>
            <Button {...props} variant={props.variant || "contained"} />
        </ThemeProvider>
    )
}

export default StyledButton
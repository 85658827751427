import {FC, ReactElement, useEffect, useState} from "react"
import PeopleIcon from '@mui/icons-material/People';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import EmployeesSection from "./EmployeesSection";
import FinancesSection from "./FinancesSection";
import {useNavigate} from "react-router-dom";
import {useOrganization, useProfile} from "@kashalot-web/react/dist";
import MainSection from "./MainSection";
import MainPageSketch from "../../../lab/MainPageSketch";
import {GET_ORGANIZATION_DETAILS_ID} from "../CompanyRequestIDs";

interface ICompanyMainPage {
    section: "main" | "employees" | "reports"
}

const CompanyMainPage: FC<ICompanyMainPage> = ({section}): ReactElement => {
    const {logout} = useProfile()
    const navigate = useNavigate()

    const {organization, getOrganizationDetails} = useOrganization()

    useEffect(() => {
        getOrganizationDetails(GET_ORGANIZATION_DETAILS_ID)
    }, [])

    return (
        <MainPageSketch
            title={organization?.name || "Офис"}
            sections={[
                {
                    title: "Главная",
                    name: "main",
                    icon: <AutoAwesomeMosaicIcon/>,
                    onClick: () => navigate('/main'),
                    body: <MainSection/>,
                },
                {
                    title: "Сотрудники",
                    name: "employees",
                    icon: <PeopleIcon/>,
                    onClick: () => navigate('/employees'),
                    body: <EmployeesSection/>,
                },
                {
                    title: "Отчеты",
                    name: "reports",
                    icon: <MonetizationOnIcon/>,
                    onClick: () => navigate('/reports'),
                    body: <FinancesSection/>,
                },
                {
                    title: "Выход",
                    name: "exit",
                    icon: <ExitToAppIcon/>,
                    onClick: () => logout(),
                    body: <></>,
                }
            ]}
            selectedSection={section}
        />
    )
}

export default CompanyMainPage
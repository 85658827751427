import {FC, ReactElement} from "react";
import {Grid, Paper} from "@mui/material";
import RequisitesForm from "./RequisitesForm";


const emptyRequisites: Requisites = {
    legalName: "",
    legalAddress: "",
    bankBic: "",
    bankName: "",
    bankAccountNumber: "",
    correspondentBankAccountNumber: "",
    inn: "",
    kpp: "",
    directorName: "",
    accountantName: "",
    zipCode: "",
    taxType: "general"
}


interface IBlankRequisites {
    onCreate?: (newRequisites: Requisites) => void
}

const BlankRequisites: FC<IBlankRequisites> = ({onCreate}): ReactElement => {
    return (
        <Grid container>
            <Paper component={Grid} item sx={{p: '16px'}}>
                <RequisitesForm
                    defaultRequisites={emptyRequisites}
                    onSaveRequisites={onCreate}
                    actionType={"create"}
                />
            </Paper>
        </Grid>
    )
}

export default BlankRequisites
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {FC, ReactElement, useState} from "react";
import {Container, Divider, Grid, GridProps, Paper, Typography} from "@mui/material";

interface IPaperContainer extends GridProps {
    title?: string
    Icon?: OverridableComponent<any>
}

const PaperContainer: FC<IPaperContainer> = ({children, title, Icon, ...gridProps}): ReactElement => {

    return (
        <Container component={Paper} sx={{mb: '18px'}}>
            <Grid container direction="column" wrap="nowrap">
                {title && Icon && (
                    <>
                        <Grid container item alignItems="center" sx={{p: '20px 10px'}}>
                            <Icon fontSize="large" style={{color: 'gray'}} sx={{mr: '20px'}}/>
                            <Grid item>
                                <Typography variant="h4">{title}</Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </>
                )}
                <Grid {...gridProps}>
                    {children}
                </Grid>
            </Grid>
        </Container>
    )
}

export default PaperContainer
import {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import {Autocomplete, Button, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import {useDispatcher} from "@kashalot-web/react";
import PhoneNumber from "@kashalot-web/core/dist/Utils/PhoneNumber"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import {useDispatcherRequest} from "@kashalot-web/react/dist";
import {useNavigate} from "react-router-dom";

const CREATE_BRANCH_ID = "create-branch-" + makeID(8)
const GET_CLIENTS_ID = "get clients-" + makeID(8)

const CreateBranch: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {getClients, clientsManager, createBranch} = useDispatcher()

    const [loadingClients, setLoadingClients] = useState<boolean>(false)
    const [getClientsStatus] = useDispatcherRequest(GET_CLIENTS_ID)
    useEffect(() => {
        getClients(GET_CLIENTS_ID)
    }, [])
    useEffect(() => {
        if (getClientsStatus === "pending") {
            setLoadingClients(true)
        } else {
            setLoadingClients(false)
        }
    }, [getClientsStatus])


    const [creatingStatus, removeCreateRequest] = useDispatcherRequest(CREATE_BRANCH_ID)
    const [branchForm, setBranchForm] = useState<BranchForm>({
        name: "",
        fio: "",
        email: "",
        phone: "",
        companyID: ""
    })
    const handleBranchForm = (param: "name" | "fio" | "email" | "phone", value: string) =>
        setBranchForm({...branchForm, [param]: value})
    const [targetOrganization, setTargetOrganization] = useState<ShortCompany | null>(null)
    useEffect(() => {
        setBranchForm({...branchForm, companyID: targetOrganization ? targetOrganization.id : ""})
    }, [targetOrganization])

    const validate = () => {
        const {name, fio, email, phone, companyID} = branchForm
        return name && fio && email && companyID && PhoneNumber.validateWithMask(phone) && creatingStatus !== "pending"
    }
    const onCreate = () => {
        createBranch(branchForm, CREATE_BRANCH_ID)
    }
    useEffect(() => {
        if (creatingStatus === "success") {
            removeCreateRequest()
            navigate(-1)
        }
    }, [creatingStatus])


    return (
        <Grid
            item container
            direction={"column"}
            wrap={"nowrap"}
            spacing={2}
        >
            <Grid item><Typography variant="h5">Компания</Typography></Grid>
            <Grid item><Autocomplete
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                options={clientsManager?.getCompanies() || []}
                loading={loadingClients}
                value={targetOrganization}
                onChange={(event, value) => setTargetOrganization(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Компания"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loadingClients ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            /></Grid>

            <Grid item><Typography variant="h5">Контактные данные</Typography></Grid>
            <Grid item><TextField
                label={"Название филиала"}
                value={branchForm.name}
                onChange={event => handleBranchForm("name", event.target.value)}
            /></Grid>
            <Grid item><TextField
                label={"ФИО управляющего"}
                value={branchForm.fio}
                onChange={event => handleBranchForm("fio", event.target.value)}
            /></Grid>
            <Grid item><TextField
                label={"Email управляющего"}
                value={branchForm.email}
                onChange={event => handleBranchForm("email", event.target.value)}
            /></Grid>
            <Grid item><InputMask
                mask="+7 (999) 999-9999"
                value={branchForm.phone ? branchForm.phone : "+"}
                onChange={(event) => handleBranchForm("phone", event.target.value)}
                children={((inputProps: any) =>
                    <TextField
                        {...inputProps}
                        label={"Номер телефона"}
                        type="tel"
                    />) as unknown as ReactNode
                }
            /></Grid>
            {creatingStatus === "error" && <Grid item><Typography color={"error"}>Ошибка при создании</Typography></Grid>}
            <Grid item><Button
                variant={"contained"}
                onClick={onCreate}
                disabled={!validate()}
            >Создать</Button></Grid>
        </Grid>
    )
}

export default CreateBranch
import {Grid, TextField} from "@mui/material";
import {FC, ReactElement, useEffect, useState} from "react";
import TaxiParks from "@kashalot-web/core/dist/TaxiInfo/TaxiParks"
import PaperContainer from "../../lab/Components/PaperContainer";
import StyledButton from "../StyledButton";

const ChaykaIcon = (props: any) => {
    return (
        <Grid item {...props}>
            <img
                height={50}
                src={TaxiParks.CHAYKA.logo}
            />
        </Grid>
    )
}

interface IChaykaFormComponent {
    defaultChaykaForm: ChaykaForm
    saveForm: (newForm: ChaykaForm) => void
    deleteForm: () => void
}

const ChaykaFormComponent: FC<IChaykaFormComponent> = (props): ReactElement => {
    const {defaultChaykaForm, saveForm, deleteForm} = props
    const [chaykaForm, setChaykaForm] = useState<ChaykaForm>(defaultChaykaForm)
    const chaykaHandleChanged = (param: "token" | "name", value: string) => setChaykaForm({...chaykaForm, [param]: value})

    useEffect(() => {
        setChaykaForm(defaultChaykaForm)
    }, [defaultChaykaForm])

    const validate = () => {
        return defaultChaykaForm.token !== chaykaForm.token || defaultChaykaForm.name !== chaykaForm.name
    }

    return (
        <PaperContainer
            title={"Чайка " + `(${defaultChaykaForm.name})`}
            Icon={ChaykaIcon}
            direction={"column"}
            wrap={"nowrap"}
            container
            spacing={2}
            sx={{p: '16px 0'}}
        >
            <Grid item><TextField
                label={"Название"}
                value={chaykaForm.name}
                onChange={e => chaykaHandleChanged("name", e.target.value)}
            /></Grid>
            <Grid item><TextField
                label={"Token"}
                value={chaykaForm.token}
                onChange={e => chaykaHandleChanged("token", e.target.value)}
                sx={{width: 468}}
            /></Grid>
            <Grid item container spacing={2}>
                <Grid item><StyledButton
                    disabled={!validate()}
                    onClick={() => saveForm(chaykaForm)}
                >
                    Сохранить
                </StyledButton></Grid>
                <Grid item><StyledButton
                    onClick={deleteForm}
                >
                    Удалить
                </StyledButton></Grid>
            </Grid>
        </PaperContainer>
    )
}

export default ChaykaFormComponent